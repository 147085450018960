
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.cms-form {
  ::v-deep {
    .g-field,
    .skeleton {
      &.name {
        @include bp-lg-laptop {
          grid-column: 1 / 7;
        }
      }
      &.companyId {
        @include bp-lg-laptop {
          grid-column: 7 / 11;
        }
      }
      &.description {
        .input-element {
          min-height: 132px;
        }
      }
    }
  }
}
